export default {
  install (Vue) {
    Vue.directive('min-width', {
      inserted: (el, binding) => {
        const width = binding.value || 0.88;
        el.style.minWidth = `${width}rem`;
        if (binding.modifiers.no) {
          el.style.minWidth = 'unset';
        }
      }
    })
  }
}
