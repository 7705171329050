import $x from "@/libs/$x";

export default {
  namespaced: true,
  state: {
    statusList: [],
    statusListDictMap: {
      1: $t("dai-fu-kuan"),
      2: $t("dai-que-ren"),
      3: $t("yi-que-ren"),
      4: $t("yi-qu-xiao"),
      5: $t("yi-tui-kuan"),
    },
    topSearchOptionsList: [],
    topSearchOptionsListDictMap: {
      1: $t("ding-dan-xu-lie-hao"),
      2: $t("ke-hu-xing-ming"),
      3: $t("ke-hu-dian-hua"),
      4: $t("ke-hu-id"),
      5: $t("xiao-fei-pin-ming-cheng"),
      6: $t("bei-zhu"),
      7: $t("xiao-shou-xing-ming"),
      8: $t("ji-shi-xing-ming"),
    },
    sourceList: [],
    sourceListDictMap: {
      1: $t("wang-dian"),
      2: $t("men-dian"),
    },
    bonusPointUsedList: [],
    bonusPointUsedListDictMap: {
      1: $t("shi"),
      2: $t("fou"),
    },
  },
  mutations: {
    // 订单状态列表
    setStatusList(state, data) {
      state.statusList = data;
    },
    // 订单头部搜索选项列表
    setTopSearchOptionsList(state, data) {
      state.topSearchOptionsList = data;
    },
    // 订单来源
    setSourceList(state, data) {
      state.sourceList = data;
    },
    // 订单是否积分抵扣
    setBonusPointUsedList(state, data) {
      state.bonusPointUsedList = data;
    },
  },
  actions: {
    handleStatusList({ state, commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/orderStatuslist`
        );
        if (code === 200) {
          if (data) {
            const res = data.map((item) => {
              return {
                ...item,
                dictValue: state.statusListDictMap[item.dictOrder],
              };
            });
            commit("setStatusList", res);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    handleTopSearchOptionsList({ state, commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/commonOrderTopSearchOptionlist`
        );
        if (code === 200) {
          if (data) {
            const res = data.map((item) => {
              return {
                ...item,
                dictValue: state.topSearchOptionsListDictMap[item.dictOrder],
              };
            });
            commit("setTopSearchOptionsList", res);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    handleSourceList({ state, commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/orderSourcelist`
        );
        if (code === 200) {
          if (data) {
            const res = data.map((item) => {
              return {
                ...item,
                dictValue: state.sourceListDictMap[item.dictOrder],
              };
            });
            commit("setSourceList", res);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    handleBonusPointUsedList({ state, commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/orderBonusPointUsedlist`
        );
        if (code === 200) {
          if (data) {
            const res = data.map((item) => {
              return {
                ...item,
                dictValue: state.bonusPointUsedListDictMap[item.dictOrder],
              };
            });
            commit("setBonusPointUsedList", res);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
  },
};
