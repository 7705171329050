export default (fieldList) => {
  const obj = {}
  const lang = localStorage.getItem("lang")
  // 循环字段列表
  for (const item of fieldList) {
    const type = (item.type === 'select' || item.type === 'radio' || item.type === 'mutiSelect') 
      ? (lang === 'en' ? 'select' : '选择') 
      : (lang === 'en' ? 'enter' : '输入')
    if (item.required) {
      if (item.validator) {
        obj[item.value] = {
          required: item.required,
          validator: item.validator,
          trigger: 'blur'
        }
      } else {
        if (item.type === 'slot') {
          obj[item.value] = {
            required: item.required,
            message: lang === 'en' ? 'This field cannot be empty' : '该项内容不能为空',
            trigger: 'blur'
          }
        } else {
          obj[item.value] = {
            required: item.required,
            message: lang === 'en' 
              ? `Please ${type} ${item.label}` 
              : '请' + type + item.label,
            trigger: 'blur'
          }
        }
      }
    } else if (item.validator) {
      obj[item.value] = {
        validator: item.validator,
        trigger: 'blur'
      }
    }
  }
  // formInfo.rules = obj
  return obj
}
