export default {
  install(Vue) {
    Vue.directive("custom-clipboard", {
      bind: (el, binding, vnode) => {
        el.addEventListener("click", () => {
          const text = binding.value;
          const textarea = document.createElement("textarea");
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();

          let success = false;
          try {
            success = document.execCommand("copy");
          } catch (e) {
            success = false;
          }
          document.body.removeChild(textarea);
          if (success) {
            el.dispatchEvent(new Event("clipboard-success"));
          } else {
            el.dispatchEvent(new Event("clipboard-error"));
          }
        });
      },
      unbind(el) {
        // 解绑时移除事件监听器，防止内存泄漏
        el.removeEventListener("click", this.listener);
      },
    });
  },
};
