import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "@/i18n/langs/zh";
import en from "@/i18n/langs/en";

import EleZh from "element-ui/lib/locale/lang/zh-CN";
import EleEn from "element-ui/lib/locale/lang/en";
Vue.use(VueI18n);

let lang = "zh";
const query = window.location.search;
const urlParams = new URLSearchParams(query);
const queryLang = urlParams.get('lang');

if (queryLang) {
  lang = queryLang;
  localStorage.setItem("lang", lang);
} else {
  lang = localStorage.getItem("lang") || "zh";
}

const i18n = new VueI18n({
  globalInjection: true, // 允许全局访问 $t 函数
  locale: lang, // 设置默认语言
  messages: {
    zh: { ...zh, ...EleZh },
    en: { ...en, ...EleEn },
  },
  silentTranslationWarn: true,
});

const $t = function (key, values) {
  return i18n.t(key, values);
};

Vue.prototype.$t = $t;
Vue.prototype.$lang = Vue.$lang = lang;
window.$t = $t;

export { i18n, $t, lang };
