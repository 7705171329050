import $x from "@/libs/$x";

export default {
  namespaced: true,
  state: {
    roleList: [],
    sexList: [],
    productLabelList: [],
    productTypeList: [],
    productType2List: [],
    ecCascaderProductTypeList: [],
    vaildStaffList: [],
    sellStaffList: [],
    sellStaffListMore: [],
    serviceLabelList: [],
    craftsmanlist: [],
    craftsmanlistMore: [],
    serviceTypeList: [],
    productUnitList: [],
    //1:mobile 2:tablet 3:pc
    platformX: 1,
  },
  mutations: {
    setRoleList: (state, data) => {
      state.roleList = data;
    },
    setSexList: (state, data) => {
      state.sexList = data;
    },
    setVaildStaffList: (state, data) => {
      state.vaildStaffList = data.map((item) => {
        return {
          key: item.userName,
          value: item.userName,
        };
      });
    },
    setSellStaffList: (state, data) => {
      state.sellStaffList = data.map((item) => {
        return {
          key: item.userName,
          value: item.id,
        };
      });
    },
    setSellStaffListMore: (state, data) => {
      state.sellStaffListMore = data.map((item) => {
        return {
          key: item.userName,
          value: item.id,
          userAcount: item.userAcount,
        };
      });
    },
    setCraftsmanlist: (state, data) => {
      state.craftsmanlist = data.map((item) => {
        return {
          key: item.userName,
          value: item.id,
        };
      });
    },
    setCraftsmanlistMore: (state, data) => {
      state.craftsmanlistMore = data.map((item) => {
        return {
          key: item.userName,
          value: item.id,
          userAcount: item.userAcount,
        };
      });
    },
    setServiceLabelList: (state, data) => {
      state.serviceLabelList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setServiceTypeList: (state, data) => {
      state.serviceTypeList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setProductLabelList: (state, data) => {
      state.productLabelList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    // 分类1/父类列表
    setProductTypeList: (state, data) => {
      if ($x.platformType == "ec") {
        state.productTypeList = data.map((item) => {
          return {
            key: item.id,
            value: item.id,
            label: item.type,
          };
        });
        return;
      }
      state.productTypeList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    // 分类1级联
    setEcCascaderProductTypeList: (state, data) => {
      state.ecCascaderProductTypeList = data.map((item) => {
        const children = item.productTypeItemList.map((c) => {
          return {
            value: c.type,
            label: c.type,
          };
        });
        return {
          value: item.type,
          label: item.type,
          children,
        };
      });
    },
    // 分类2
    setProductType2List: (state, data) => {
      state.productType2List = data.map((item) => {
        return {
          key: item.otherTypeItemName,
          value: item.otherTypeItemName,
        };
      });
    },
    setProductUnitList: (state, data) => {
      state.productUnitList = data.map((item) => {
        return {
          key: item.dictValue,
          value: item.dictValue,
        };
      });
    },
    setPlatformX: (state, data) => {
      state.platformX = data;
    },
  },
  actions: {
    getProductLabelList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/productLabel`
        );
        if (code === 200) {
          if (data) {
            commit("setProductLabelList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getProductUnitList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/productUnit`
        );
        if (code === 200) {
          if (data) {
            commit("setProductUnitList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getVaildStaffList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/stafflist`
        );
        if (code === 200) {
          if (data) {
            commit("setVaildStaffList", data);
            commit("setSellStaffList", data);
            commit("setSellStaffListMore", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getCraftsmanlist({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/craftsmanlist`
        );
        if (code === 200) {
          if (data) {
            commit("setCraftsmanlist", data);
            commit("setCraftsmanlistMore", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getServiceLabelList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/serviceLabel`
        );
        if (code === 200) {
          if (data) {
            commit("setServiceLabelList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getServiceTypeList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/serviceType`
        );
        if (code === 200) {
          if (data) {
            commit("setServiceTypeList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getProductTypeList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const url =
          $x.platformType == "ec"
            ? `/platform/${shopId}/common/productTypeWithItem`
            : `/platform/${shopId}/common/productType`;
        const { data, code, message } = await $x.get(url);
        if (code === 200) {
          if (data) {
            commit("setProductTypeList", data);
            if ($x.platformType == "ec") {
              commit("setEcCascaderProductTypeList", data);
            }
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getProductType2List({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const url = `/platform/${shopId}/product/queryList`;
        const { data, code, message } = await $x.get(url);
        if (code === 200) {
          if (data) {
            console.log("🍉", "更新分类2vuex数据");
            commit("setProductType2List", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getRoleList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/rolelist`
        );
        if (code === 200) {
          if (data) {
            commit("setRoleList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    screenSizeChange({ commit, dispatch, getters }, width) {
      // if (width < 768) {
      //   commit('setPlatformX', 1)
      // } else {
      //   commit('setPlatformX', 2)
      // }
      if (width < 768) {
        commit("setPlatformX", 1);
      } else if (width < 1300) {
        commit("setPlatformX", 2);
      } else {
        commit("setPlatformX", 3);
      }
    },
    getSexList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/sexuallist`
        );
        if (code === 200) {
          if (data) {
            commit(
              "setSexList",
              data.map((item) => {
                return {
                  key: item.dictValue,
                  value: item.dictKey,
                };
              })
            );
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
  },
};
